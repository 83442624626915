@import url('https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.header-img {
    background-image: url("/public/Country/images/tractor.jpg");
    background-position: top;
    background-size: cover;
    height: 200px;
}

.login-content {
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1)), url("/public/Country/images/fond.jpg");
    background-position: top;
    background-size: contain;
    background-repeat: no-repeat;
    margin-bottom: 30px;
    .content {
        display: flex;
        justify-content: space-around;
        padding-top: 50px;

        form {
            margin-bottom: 40px;

            .login {
                position: relative;
                display: flex;
                flex-direction: column;
                align-items: center;
                padding: 50px 50px;
                background: #FFFFFF 0% 0% no-repeat padding-box;
                box-shadow: 0px 2px 8px #00000029;
                border-radius: 10px;


                .icone {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background: #915833 0% 0% no-repeat padding-box;
                    border-radius: 50%;
                    height: 70px;
                    width: 70px;
                    position: absolute;
                    top: 0px;
                    right: 50%;
                    transform: translate(50%, -50%);

                    img {
                        width: 35px;
                        height: 35px;
                    }
                }


                .title {
                    display: flex;

                    h2 {
                        font: normal normal 900 24px Jost;
                        letter-spacing: 0px;
                        color: #064C37;
                        text-transform: uppercase;
                        position: relative;
                        border-radius: 8px;
                        padding: 4px 15px;
                        padding-left: 20px;
                        margin-bottom: 10px;
                        margin-top: 0;
                    }

                    img {
                        position: absolute;
                        width: 33px;
                        height: 50px;
                        position: absolute;
                        left: -10px;
                        top: -5px;
                    }
                }

                .form-field {
                    display: flex;
                    flex-direction: column;
                    align-items: center;

                    label {
                        font: italic normal 600 18px Jost;
                        letter-spacing: 0px;
                        color: #064C37;
                        margin-bottom: 5px;
                    }

                    input {
                        height: 40px;
                        width: 350px;
                        text-align: center;
                        background: #FFFFFF 0% 0% no-repeat padding-box;
                        box-shadow: 0px 3px 6px #0000001C;
                        border: 1px solid #EBEBEB;
                        border-radius: 10px;
                        font: italic normal 400 16px Jost;
                        letter-spacing: 0px;
                        color: #064C37;
                    }

                    a {
                        text-decoration: none;
                        font: italic normal normal 14px Jost;
                        letter-spacing: 0px;
                        color: #7E7E7E;
                        margin-top: 15px;
                    }
                }

                .button {
                    position: absolute;
                    bottom: 0px;
                    left: 50%;
                    transform: translate(-50%, 50%);
                    background: #064C37 0% 0% no-repeat padding-box;
                    box-shadow: 0px 3px 6px #00000029;
                    border: none;
                    border-radius: 10px;
                    font: italic normal 300 16px Jost;
                    letter-spacing: 0px;
                    color: #FFFFFF;
                    cursor: pointer;
                    padding: 3px 30px;
                }

                .margin {
                    margin-top: 40px;
                }
            }
        }

        .new-customer {
            background: #FFFFFF 0% 0% no-repeat padding-box;
            box-shadow: 0px 2px 8px #00000029;
            border-radius: 10px;
            position: relative;
            height: 80px;
            width: 370px;

            h2 {
                font: normal normal 900 20px Jost;
                letter-spacing: 0px;
                color: #064C37;
                text-transform: uppercase;
                text-align: center;
            }

            .registration {
                position: absolute;
                bottom: 0px;
                left: 50%;
                transform: translate(-50%, 50%);

                a {
                    text-decoration: none;
                }

                button {
                    background: #064C37 0% 0% no-repeat padding-box;
                    box-shadow: 0px 3px 6px #00000029;
                    border: none;
                    border-radius: 10px;
                    font: italic normal 300 16px Jost;
                    letter-spacing: 0px;
                    color: #FFFFFF;
                    cursor: pointer;
                    padding: 3px 30px;
                }
            }
        }
    }

}


// RESPONSIVE

@media (max-width: 1100px) {
    .content {
        flex-direction: column;
        align-items: center;

        .new-customer {
            margin-bottom: 40px;
        }
    }
}


@media (max-width: 500px) {
    .login {
        width: auto !important;
        padding: 50px 30px !important;

        .form-field {

            input {
                width: 250px !important;
            }
        }
    }

    .new-customer {
        width: 100% !important;
    }
}